import {Component, Renderer2} from '@angular/core';
import {Router} from '@angular/router';
import {HomePage} from '../home/home.page';
import {MenuPage} from '../menu/menu.page';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';

@Component({
	selector: 'app-privacy',
	templateUrl: './privacy.page.html',
	styleUrls: ['privacy.page.scss']
})
export class PrivacyPage {
	static url = 'privacy';
	static externalUrl = 'https://avec.ch/de/app/datenschutz/#24-7-app';
	constructor(
		private repository: RepositoryService,
		private router: Router
	) {}

	static async navigate(router: Router) {
		window.open('https://avec.ch/de/app/datenschutz/#24-7-app', '_blank');
	}

	goBack() {
		if (this.repository._order === null) {
			HomePage.navigate(this.router);
		} else {
			MenuPage.navigate(this.router);
		}
	}
}
