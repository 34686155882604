import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {sleep} from 'src/smoothr-web-app-core/utils/utils';
import {Router} from '@angular/router';
import {HomePage} from 'src/app/pages/home/home.page';

@Component({
	selector: 'app-short-location-error-modal',
	templateUrl: './short-location-error-modal.component.html',
	styleUrls: ['short-location-error-modal.component.scss']
})
export class ShortLocationErrorModalComponent implements OnInit {
	constructor(
		private modalCtrl: ModalController,
		private route: Router
	) {}

	ngOnInit() {}
	static async show(modalCtrl: ModalController): Promise<any> {
		const modal = await modalCtrl.create({
			cssClass: 'short-location-error-modal auto-height',
			component: ShortLocationErrorModalComponent,
			componentProps: {},
			showBackdrop: true,
			backdropDismiss: true
		});
		await modal.present();
		const result = await modal.onDidDismiss();
		sleep(100);
		return result;
	}

	back() {
		this.dismiss();
		HomePage.navigate(this.route);
	}

	dismiss() {
		this.modalCtrl.dismiss();
	}
}
