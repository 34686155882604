import {Component, Input} from '@angular/core';
import {ModalController} from '@ionic/angular';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import Hours from '../../../smoothr-web-app-core/models/Hours';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import {AppComponent} from '../../app.component';
import {TranslateService} from '@ngx-translate/core';
import {TimeUtils} from '../../../smoothr-web-app-core/utils/time-utils';
import {environment} from 'src/environments/environment';
import {Api} from '../../../smoothr-web-app-core/api/api';
import {ShortInfoModalComponent} from '../short-info-modal/short-info-modal.component';
import {numberToCurrency} from 'src/smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-working-hours',
	templateUrl: './working-hours.component.html',
	styleUrls: ['working-hours.component.scss']
})
export class WorkingHoursComponent {
	relevantHours = [];
	_venue: Venue = null;
	@Input() set venue(venue: Venue) {
		console.log('venue', venue);

		this._venue = venue;
		this.relevantHours = TimeUtils.sanitizeHours(venue.cateringHoursDelivery);
		console.log('this.relevantHours', this.relevantHours);
	}

	@Input() type: PreorderType;

	pt = PreorderType;
	environment = environment;
	numberToCurrency = numberToCurrency;
	// tslint:disable-next-line:variable-name
	private _longDays = [
		this.translate.instant('weekday.monday'),
		this.translate.instant('weekday.tuesday'),
		this.translate.instant('weekday.wednesday'),
		this.translate.instant('weekday.thursday'),
		this.translate.instant('weekday.friday'),
		this.translate.instant('weekday.saturday'),
		this.translate.instant('weekday.sunday')
	];

	constructor(
		private modalCtrl: ModalController,
		private translate: TranslateService
	) {}

	// tslint:disable-next-line:variable-name
	private _days = [
		this.translate.instant('weekday.monday_short'),
		this.translate.instant('weekday.tuesday_short'),
		this.translate.instant('weekday.wednesday_short'),
		this.translate.instant('weekday.thursday_short'),
		this.translate.instant('weekday.friday_short'),
		this.translate.instant('weekday.saturday_short'),
		this.translate.instant('weekday.sunday_short')
	];

	get days() {
		return this._longDays;
	}

	dayText(schedule: Hours[], index: number): string[] {
		const res = [];
		const allConnected = schedule.map(hour => hour.connectedWithPrev).indexOf(false) < 0;
		for (const hour of schedule) {
			if (hour.connectedWithPrev && !allConnected) {
				continue;
			}
			if (hour.weekday === index) {
				const hours = TimeUtils.hoursToSchedule(hour);
				res.push(hours.openedAt.format('HH:mm') + ' - ' + hours.closedAt.format('HH:mm'));
			}
		}
		if (res.length !== 0) {
			return res;
		}
		return [this.translate.instant('venue_suggestion.close')];
	}

	dismiss() {
		this.modalCtrl.dismiss();
	}
}
