import {Component} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {environment} from 'src/environments/environment';
import Localization from '../../../smoothr-web-app-core/models/Localization';
import {numberToCurrency, sleep} from 'src/smoothr-web-app-core/utils/utils';

export enum EnumResult {
	goToPayment = 'goToPayment',
	editOrder = 'editOrder'
}

@Component({
	selector: 'app-special-offer-modal',
	templateUrl: './special-offer-modal.component.html',
	styleUrls: ['special-offer-modal.component.scss']
})
export class SpecialOfferModalComponent {
	increasePrice = false;
	price: string = '';
	environment = environment;

	constructor(private modalCtrl: ModalController) {}

	static async show(modalCtrl: ModalController, price: string, increasePrice: boolean = false): Promise<EnumResult> {
		const modal = await modalCtrl.create({
			cssClass: 'special-offer-modal auto-height',
			component: SpecialOfferModalComponent,
			componentProps: {
				price,
				increasePrice
			},
			showBackdrop: true,
			backdropDismiss: true
		});
		await modal.present();
		const result = (await modal.onDidDismiss()).data;
		await sleep(200);
		return result;
	}

	dismiss() {
		this.modalCtrl.dismiss();
	}

	goToPayment() {
		this.modalCtrl.dismiss(EnumResult.goToPayment);
	}

	editOrder() {
		this.modalCtrl.dismiss(EnumResult.editOrder);
	}
}
