import {apiEnvironment} from '../smoothr-web-app-core/environments/apiEnvironment';

export const avecCustomerGroup = 'brezel_koenig';
export const customerGroup = avecCustomerGroup;

const supportEmail = 'brezel_koenig@smoothr.de';
const firebaseConfig = {
	brezel_koenig: {
		apiKey: 'AIzaSyBCdU_ACStbV3huSwFmqpwILMMrGZJKQ6w',
		authDomain: 'avec-f17bd.firebaseapp.com',
		projectId: 'avec-f17bd',
		storageBucket: 'avec-f17bd.appspot.com',
		messagingSenderId: '120278036370',
		appId: '1:120278036370:web:5c32beb0d86b2410a39b4b'
	}
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyBJFXO1ZZEC401SqDKk7CVsxYM1L0aHBys',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup,
	...apiEnvironment,
	countryList: ['de'],
	hasRecommendations: true,
	showOrderPriceFromBackend: false,
	showDiffrenceModal: false,
	payPalValidatePrice: false,
	payPalMinPrice: 0,
	getPriceByUser: '',
	merchantId: '3000028456'
};
