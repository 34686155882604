import {Component} from '@angular/core';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {Router} from '@angular/router';
import {HomePage} from '../home/home.page';
import {MenuPage} from '../menu/menu.page';
import RepositoryDirective from 'src/smoothr-web-app-core/directives/repository-directive';
import {TranslateService} from '@ngx-translate/core';

@Component({
	selector: 'app-allergen',
	templateUrl: './allergen.page.html',
	styleUrls: ['allergen.page.scss']
})
export class AllergenPage {
	static url = 'allergen';
	constructor(
		public repository: RepositoryService,
		private router: Router,
		public transService: TranslateService
	) {}
	public additives = {
		'1': this.transService.instant('additives.1'),
		'2': this.transService.instant('additives.2'),
		'3': this.transService.instant('additives.3'),
		'4': this.transService.instant('additives.4'),
		'5': this.transService.instant('additives.5'),
		'6': this.transService.instant('additives.6'),
		'7': this.transService.instant('additives.7'),
		'8': this.transService.instant('additives.8'),
		'9': this.transService.instant('additives.9'),
		'10': this.transService.instant('additives.10'),
		'11': this.transService.instant('additives.11'),
		'12': this.transService.instant('additives.12')
	};

	public allergens = [
		'Wheat',
		'Rye',
		'Barley',
		'Oats',
		'Spelt',
		'Kamut',
		'Gluten',
		'Crustaceans',
		'Egg',
		'Fish',
		'Peanuts',
		'Soy',
		'Milk',
		'Nuts',
		'Celery',
		'Mustard',
		'Seasame',
		'Sulphites',
		'Lupin',
		'Molluscs',
		'Almonds',
		'Hazelnuts',
		'Walnuts',
		'Cashews',
		'Peacans',
		'Brazil nuts',
		'Pistachio',
		'Macadamia'
	];

	get keysOfAdditives() {
		return Object.keys(this.additives);
	}

	static navigate(router: Router) {
		router.navigateByUrl(AllergenPage.url);
	}

	goBack() {
		if (this.repository._order === null) {
			HomePage.navigate(this.router);
		} else {
			MenuPage.navigate(this.router);
		}
	}
}
