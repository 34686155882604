export enum PaymentMethod {
	PAYPAL = 'paypal',
	CASH = 'cash',
	CREDIT_CARD = 'credit_card',
	VISA = 'visa',
	MASTERCARD = 'mastercard',
	SEPA = 'sepa',
	BALANCE = 'balance',
	POS = 'pos',
	SOFORTBANKING = 'sofortbanking',
	GPAY = 'google_pay',
	APAY = 'apple_pay',
	WORLDLINE = 'worldline_raw',
	VR_SEPA = 'vr_sepa'
}
