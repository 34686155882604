import {Component, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {PreorderType} from 'src/smoothr-web-app-core/enums/PreorderType';
import Localization from 'src/smoothr-web-app-core/models/Localization';
import Order from 'src/smoothr-web-app-core/models/Order';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import {RepositoryService} from 'src/smoothr-web-app-core/services/repository/repository.service';
import {sleep} from 'src/smoothr-web-app-core/utils/utils';
import {SelectLanguageModalComponent} from '../select-language-modal/select-language-modal.component';

@Component({
	selector: 'app-select-table-modal',
	templateUrl: './select-table-modal.component.html',
	styleUrls: ['select-table-modal.component.scss']
})
export class SelectTableModalComponent implements OnInit {
	venue: Venue;
	order: Order;
	selectedTable = null;
	tableNumber = '';
	tableError = null;
	loading = false;
	pt = PreorderType;
	constructor(
		private modalCtrl: ModalController,
		private repository: RepositoryService,
		private snackbarCtrl: MatSnackBar,
		public translate: TranslateService
	) {}
	ngOnInit() {
		if (this.order?.tableNumber && this.order?.tableNumber !== 'preorder_table') {
			this.tableNumber = this.order?.tableNumber;
		}
	}
	static async show(
		modalCtrl: ModalController,
		venue: Venue,
		order: Order,
		fullScreen?: false
	): Promise<{
		table: string;
		tableId: string;
	}> {
		const modal = await modalCtrl.create({
			component: SelectTableModalComponent,
			cssClass: 'select-table-modal',
			componentProps: {
				venue,
				order
			},
			showBackdrop: true
		});
		await modal.present();
		const result = (await modal.onDidDismiss()).data;
		return result;
	}
	async goBack() {
		await this.modalCtrl.dismiss();
	}
	async close() {
		this.loading = true;
		if (!(await this.validate())) {
			await sleep(100);
			this.loading = false;
			return;
		}
		this.loading = false;
		this.modalCtrl.dismiss({
			table: this.selectedTable.number,
			tableId: this.selectedTable._id
		});
	}
	async validate(): Promise<boolean> {
		try {
			this.tableError = null;
			this.selectedTable = (await this.repository.getTable(this.venue._id, this.tableNumber)).data;
			if (!this.selectedTable) {
				this.tableError = this.translate.instant('select_table.error');
				this.selectedTable = null;
				return false;
			}
			if (!this.selectedTable?.active) {
				this.tableError = this.translate.instant('home_page.scan_table_error');

				return false;
			}
			return true;
		} catch (e) {
			this.tableError = this.translate.instant('select_table.error');
			this.selectedTable = null;
			return false;
		}
	}

	async selectLanguageModal() {
		await SelectLanguageModalComponent.show(this.modalCtrl);
	}
}
